// To manage the link that we are on currently we need to create State that holds that information
import { useState, useEffect } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";

// Importing react-scroll for smooth scrolling
import { Link } from "react-scroll";

// Importing images 
import logo from '../assets/images/vg.png'
import navIcon1 from '../assets/images/nav-icon1.svg';
import navIcon2 from '../assets/images/github.svg';
import navIcon3 from '../assets/images/LeetCode_Logo_1.png';


// Creating funtional componenet for now with no parameters
export const NavBar = () => {

  // activeLink is the state which holds the information about current Link
  // setActiveLick is the intial state i.e. home
  const [activeLink, setActiveLink] = useState('home');

  // Inorder to change the effects when scrolled we need add scrolled state which determine 
  // whether or not the page was scrolled and intially its false that's why seScrolled is set to false
  const [scrolled, setScrolled] = useState(false);

  // useEffect will determine wether its been scrolled or not and will trigger
  // once the scroll starts 
  // It will fire on mount 
  useEffect(() => {

    // onScroll is a function that determine what happens on scroll
    const onScroll = () => {

      // window.scrollY is the value upto which the page has been scrolled. In this
      // case we want to set seScrolled to true whenever its scrolled above 50 
      if (window.scrollY > 50) {
        setScrolled(true);
      }

      // if the user has scrolled back or not scrolled to 50 we will set seScrolled to false
      else {
        setScrolled(false);
      }
    }

    // as onScroll is just a function and will not fire on its own. Therefore, we 
    // need to add window.addEventListener which gets fired on "scroll" and calls the 
    // onScroll function which tells what to do on scroll
    window.addEventListener("scroll", onScroll);

    // As we are adding EventListener on Mount(i.e. the moment the page loads) we need to 
    // remove it when the component gets removed from the dome 
    return () => window.removeEventListener("scroll", onScroll);
  }, [])

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  }

  return (
    // We can give our own colors here
    // We can give className based on the condition scrolled wise i.e. if scrolled is 
    // set to true it means it scrolled or else leave it as it is 
<Navbar expand="md" className={scrolled ? "scrolled" : ""} >
<Container >
{/* To add image logo to our Navbar */}
  <Navbar.Brand href="/" >
    <img style={{width: '75px', height:'auto'}} src={logo} alt="Logo" />
  </Navbar.Brand>


  {/* NavBar.Toogle is used when using our site is on mobile and our Navbar is minimized */}
  <Navbar.Toggle aria-controls="basic-navbar-nav">
    <span className="navbar-toggler-icon"></span>
  </Navbar.Toggle>
  <Navbar.Collapse id="basic-navbar-nav">
    <Nav className="ms-auto">


    {/* Based on the link that is active we will highlight the link at the top */}
      <Nav.Link href="#home" className={activeLink === 'home' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('home')}>Home</Nav.Link>
      <Nav.Link href="#experience" className={activeLink === 'experience' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('experience')}>Professional Journey</Nav.Link>
      <Nav.Link href="#skills" className={activeLink === 'skills' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('skills')}>Skills</Nav.Link>
      <Nav.Link href="#education" className={activeLink === 'education' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('education')}>Education</Nav.Link>
      <Nav.Link href="#researchPaper" className={activeLink === 'researchPaper' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('researchPaper')}>Research Paper</Nav.Link>
    </Nav>


     {/* Social media links like LinkedIn,Github and Leetcode */}
    <span className="navbar-text">
      <div className="social-icon">
        <a href="https://www.linkedin.com/in/vaibhavgaur26/"><img src={navIcon1} alt="" /></a>
        <a href="https://github.com/vaibhavgaur20"><img src={navIcon2} alt="" /></a>
        <a href="https://leetcode.com/u/VaibhavGaur45/"><img src={navIcon3} alt="" /></a>
      </div>
    </span>
  </Navbar.Collapse>
</Container>
</Navbar>



  )
}