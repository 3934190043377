import { Container, Row, Col} from "react-bootstrap";
import {ArrowRightCircle} from "react-bootstrap-icons";
import headerImg from "../assets/images/header-img.jpg"
import { useState, useEffect } from "react";

export const Banner = () => {
    // List of words that we will be displaying 
    const toRotate = ["Data Engineer","Python Developer","Software Developer"]

    //loopNum is set to 0 intially and its purpose is to indicate the word it is currently on 
    const [loopNum, setLoopNum] = useState(0);

    //deleting state means that whether or not that word is been displayed already
    // initially it is set to false as we start by typing the first word 
    const[isDeleting, setIsDeleting] = useState(false);

    // we will declare text state as our component needs to which text we are displaying 
    // right now, it will pick one alphabet and then move to another one to form a word
    const [text,setText] = useState('')
    // period :- arbitraily indicate that how much time passes between letter being typed out
    const period = 3000;

    // delta determine that when does the next letter comes after the 1st one is typed
    const [delta,setDelta] = useState(100 - Math.random() * 100);

    // This function is responsible for typing and deleting 
    useEffect(()=>{
        let ticker = setInterval(()=>{
            tick();

        // delta is the interval when the tick is getting fired 
        }, delta)

        // when the component unmount we need to clear the interval
        return () => {clearInterval(ticker)};

    // and we want this useEffect to run everytime our text gets updated
    },[text])
    
    const tick = () => {
        // i is the index to array we are pointing currenlty 
        //        (0)            (3)
        let i = loopNum % toRotate.length; 
        let fullText = toRotate[i];

        // if the current state is deleting then we will take the full text else will do other way around
        let updatedText = isDeleting ? fullText.substring(0,text.length-1) : fullText.substring(0,text.length +1);

        setText(updatedText);

        // if isDeleting is true then we increase the pace of deletion by reducing the time by half
        if(isDeleting){
            setDelta(prevDelta => prevDelta /2)
        }

        if(!isDeleting && updatedText === fullText){
            setIsDeleting(true);
            setDelta(period);
        } else if (isDeleting && updatedText === ''){
            setIsDeleting(false);
            setLoopNum(loopNum +1);
            setDelta(500);
        }
    }
    return(
        <section className="banner" id="home">
            <Container>
                <Row className="align-items-center">
                <Col xs={12} md={6} xl={5}>
                        <img style={{width: '300px', height:'auto'}} src={headerImg} alt="Header Img"/>

                    </Col>
                    <Col xs={12} md={6} xl={7}>
                        <span className="tagline">Welcome to my Portfolio</span>
                        <h1>{`Hi, I'm Vaibhav Gaur`}</h1>
                        <h2><span className="wrap"> {text}</span></h2>
                        <p>Accomplished Azure and BI Engineer with 6 years of proven history in developing and optimizing data pipelines for actionable analytics</p>
                        <button href="https://www.linkedin.com/in/vaibhavgaur26/" onClick={()=> window.location.href = "https://www.linkedin.com/in/vaibhavgaur26/"}>Let's Connect <ArrowRightCircle size={25} /></button>
                    </Col>
                    
                </Row>
            </Container>
        </section>

    )
}