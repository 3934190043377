import { Container, Row, Col} from "react-bootstrap";
import {ArrowRightCircle} from "react-bootstrap-icons";
import headerImg from "../assets/images/researchPaper.jpg"
import { useState, useEffect } from "react";

export const ResearchPaper = () => {
   
    return(
 
<section className="banner_rp" id="researchPaper">
<Container>
    <Row className="align-items-center">
    <Col xs={12} md={6} xl={5}>
            <img style={{width: '450px', height:'auto'}} src={headerImg} alt="Header Img"/>

        </Col>
        <Col xs={12} md={6} xl={7}>
            <span className="tagline">Research Paper</span>
            <h1>{`Comparison of Naive Bayes and MLP for Real-Time Product Review Analysis`} <br/>
            </h1>
           
            <p>Developed an automated system for processing real-time product reviews, incorporating user feedback to identify key requirements and preferences. Implemented text classification and vector representations for sentiment analysis on website products, enhancing customer insights and improving decision-making </p>
            <button onClick={()=> window.location.href = "https://ieeexplore.ieee.org/document/9850639"}>Check It Out <ArrowRightCircle size={25} /></button>
        </Col>
       
        
    </Row>
</Container>
</section>

    )
}