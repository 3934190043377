import { Container, Row, Col} from "react-bootstrap";
import {ArrowRightCircle} from "react-bootstrap-icons";
import headerImg from "../assets/images/transcript.jpg"
import { useState, useEffect } from "react";

export const Education = () => {
    return(
        <section className="banner_edu"  id="education" >
            <Container>
                <Row className="align-items-center">
                    <Col xs={12} md={6} xl={7}>
                        <span className="tagline">Education</span>
                        <h1>{`Bachelor's in`} <br/>
                        {`Information Technology`}</h1>
                       
                        <p>I completed my Bachelor of Technology (B.Tech) in Information Technology from Guru Gobind Singh Indraprastha University, achieving a distinction with 81.2% during the academic years 2017 to 2021 </p>
                    </Col>
                    <Col xs={12} md={6} xl={5}>
                        <img style={{width: '300px', height:'auto'}} src={headerImg} alt="Header Img"/>

                    </Col>
                    
                </Row>
            </Container>
        </section>

    )
}