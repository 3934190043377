import { Container, Nav, Tab, Row, Col } from "react-bootstrap";
import {ProjectCard} from "./ProjectCard";
import colorSharp2 from "../assets/images/color-sharp2.png";
import projImg1 from "../assets/images/project.png";
import stats from "../assets/images/stats.png";
import svm from "../assets/images/svm.png";
import ecom from "../assets/images/ecom.png";
import javacer from "../assets/images/javacer.png";
import pycer from "../assets/images/pycer.png";
import mic from "../assets/images/mic.png";
import expeImg1 from "../assets/images/techsavvy-LOGO.png"
import expeImg2 from "../assets/images/punjab-LOGO.png"
import expeImg3 from "../assets/images/UDS-LOGO.png"

export const Projects = () => {

    const projects = [
        {
        title:"E-Commerce Website",
        description:"Leveraged React to develop essential components: home page, search bar, shopping cart, user login and order history",
        imgUrl: ecom,
    },
    {
        title:"Statistical project",
        description:"Demonstrated proficiency in training a multi-class logistic regression classifier on the MNIST dataset, incorporating various regularization techniques.",
        imgUrl: stats,
    },
    {
        title:"Image Classification Using SVM",
        description:"Prepared the data, visualized the data",
        imgUrl: svm,
    },
    

];

const certification = [
    {
    title:"Analyzing and Visualizing Data with Microsoft Power BI",
    description:"Completed 'Analyzing and Visualizing Data with Microsoft Power BI,' gaining expertise in data analysis and dashboard creation using Power BI.",
    imgUrl: mic,
},
{
    title:"Completion Certificate of AWS Machine Learning from Udemy",
    description:"Completed AWS Machine Learning certification from Udemy in February 2024.",
    imgUrl: javacer,
},
{
    title:"Python- Web Development",
    description:"Completed Python Web Development at Microsoft, focusing on building and deploying web applications in October-November 2017.",
    imgUrl: pycer,
},


];

const experience = [
    {
    title:"BI and Azure Engineer",
    description:"BI and Azure Engineer at TekSavvy, optimized data pipelines, built dashboards, and implemented real-time analytics and security solutions using Azure, Snowflake, and Databricks.",
    imgUrl: expeImg1,
},
{
    title:"BI apps and Cloud Engineer",
    description:"BI and Cloud Engineer at Punjab National Bank, developed ETL pipelines, optimized data workflows, integrated data from various sources, and published Power BI reports while implementing data governance and predictive analytics models.",
    imgUrl: expeImg2,
},
{
    title:"Software Developer",
    description:"Software Developer at University De Sherbrooke, developed a Python-based semantic validator, conducted SQL testing across multiple platforms, and collaborated on GitHub for project development and documentation.",
    imgUrl: expeImg3,
},


];

    return(
        <section className="project" id="experience">
            <Container>
                <Row>
                    <Col size={12}>
                    <h2>Professional Journey</h2>
                    <Tab.Container id="projects-tabs" defaultActiveKey="first">
                    <Nav  variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item >
                      <Nav.Link eventKey="first">Experience</Nav.Link>
                    </Nav.Item>
                    <Nav.Item id="projects">
                      <Nav.Link  eventKey="second" >Projects</Nav.Link>
                    </Nav.Item>
                    <Nav.Item id="certification">
                      <Nav.Link  eventKey="third">Certification</Nav.Link>
                    </Nav.Item>
                  </Nav>

                    <Tab.Content>
                        <Tab.Pane eventKey="first">
                            <Row>
                                {
                                    experience.map((experience, index) =>{
                                        return(
                                            <ProjectCard 
                                            key={index}
                                            {...experience}
                                            />
                                        )
                                    })
                                }
                            </Row>
                        </Tab.Pane>
                        <Tab.Pane eventKey="second">
                        <Row>
                                {
                                    projects.map((projects, index) =>{
                                        return(
                                            <ProjectCard 
                                            key={index}
                                            {...projects}
                                            />
                                        )
                                    })
                                }
                            </Row>
                        </Tab.Pane>
                        <Tab.Pane eventKey="third">
                        <Row>
                                {
                                    certification.map((certification, index) =>{
                                        return(
                                            <ProjectCard 
                                            key={index}
                                            {...certification}
                                            />
                                        )
                                    })
                                }
                            </Row>
                        </Tab.Pane>

                    </Tab.Content>
                    </Tab.Container>
                    </Col>
                </Row>
            </Container>
            <img className="background-image-right" src={colorSharp2}></img>
        </section>

    )
}